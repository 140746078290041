#admin {
  &-nav {
    &-links {
      > span {
        @apply px-4 text-xs leading-7;
        display: block;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
      > a {
        @apply px-4 text-white text-opacity-75 flex items-center justify-between font-medium no-underline;
        .badge {
          @apply text-blue-900;
        }
        &:hover {
          @apply bg-blue-800 text-white text-opacity-100;
        }
        &.up-current {
          @apply bg-primary-700 text-white text-opacity-100;
        }
      }
    }
  }
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);

  &-header,
  &-footer {
    height: 50px;
    padding: 0 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > * {
      margin-bottom: 0;
    }
  }

  &-header {
    @apply border-b;
    a {
      display: inline-block;
      margin-left: 10px;
      font-size: 13px;
      text-decoration: underline;
    }
  }

  &-footer {
    @apply border-t;
  }

  &-block {
    & + .card-block {
      @apply border-t;
    }
  }

  &-block {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.5rem;
      h3 {
        margin: 0;
        font-weight: bold;
        font-size: 21px;
      }
    }
  }

  &-box {
    @apply border rounded;
    > .table tr:first-child td,
    > .table tr:first-child th {
      border-top: none;
    }
    > .table tbody tr:last-child td {
      @apply rounded-bl rounded-br;
    }
  }

  &-nav {
    > .btn,
    > .dropdown > .btn,
    > .btn-group > .dropdown > .btn,
    > .btn-group > .btn {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 40px;
    }
    > .btn + .btn,
    > .btn-group + .btn {
      margin-top: 6px;
    }
  }

  > .table {
    th {
      border-top: none;
      border-bottom-width: 1px;
    }

    tr:first-child {
      > td,
      > th {
        border-top: none;
      }
    }

    th:first-child,
    td:first-child {
      padding-left: 20px;
    }
    th:last-child,
    td:last-child {
      padding-right: 20px;
    }
  }
}

.accordion {
  &-section {
    & + & {
      margin-top: 6px;
    }
  }
  &-header {
    @apply rounded;
    display: block;
    padding: 1rem;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    background: $gray-200;
  }
  &-block {
    padding: 30px;
    border: 1px solid $gray-200;
    border-top: none;
  }
}

.table {
  margin: 0;

  .table {
    background: transparent;
  }

  td, th {
    &:first-child {
      @apply pl-6;
    }
  }

  thead {
    position: sticky;
    top: 0;
    th {
      @apply bg-gray-50 text-xs font-medium whitespace-nowrap py-0 border-t border-b border-gray-200;
      height: 32px;
      vertical-align: middle;
      a {
        text-decoration: none;
      }
    }
  }

  tr[up-expand],
  tr[up-href] {
    &:hover {
      @apply cursor-pointer bg-gray-50;
    }
  }
  tbody {
    td {
      @apply border-t-0 border-b border-gray-200 align-middle;
    }
    tr:last-child {
      td,
      th {
        border-bottom: none;
      }
    }
  }

  &-details {
    th {
      width: 125px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    td {
      font-weight: 500;
      word-wrap: break-word;
    }
    td + th {
      @apply border-l border-gray-100;
    }
  }
}

.badge {
  min-width: 20px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0;
  font-size: 11px;
  border-radius: 2px;
  color: #0f586e;
  background: #dde7ec;
  &-primary {
    color: #fff;
    background: #0f586e;
  }
  &-orange {
    @apply bg-orange-500;
    color: #fff;
  }
  &-yellow {
    @apply bg-yellow-500;
    @apply text-gray-800;
  }
}

.pagination {
  .page-link {
    font-weight: 500;
    border-radius: 3px !important;
    border: none;
    background: none;
  }
  .page-item.active .page-link {
    @apply bg-primary-500;
    color: #fff !important;
  }
}

.icon {
  vertical-align: middle;
}

dl {
  dt {
    @apply text-gray-600;
    float: left;
    clear: left;
    width: 28%;
    padding-right: 10px;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  dd {
    display: block;
    min-height: 1.5em;
    margin-left: 28%;
    font-weight: 500;
    word-wrap: break-word;
  }
}

body #intercom-container .intercom-launcher-frame {
  bottom: 70px !important;
}
