@keyframes btn-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-block {
  @apply w-full;
}

.btn {
  @apply inline-flex items-center justify-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out bg-gray-100 border border-transparent rounded-md;

  &.up-active,
  form.up-active &-submit {
    &::after {
      content: "";
      @apply inline-block w-4 h-4 ml-2 border-2 rounded-full;
      border-color: currentColor;
      border-top-color: transparent;
      animation: btn-spin 1s linear infinite;
    }
  }

  form.up-active & {
    @apply opacity-75 pointer-events-none;
  }

  &:hover {
    @apply border-gray-300;
  }

  &:focus {
    @apply border-gray-300 outline-none shadow-outline-blue;
  }

  &:active {
    @apply bg-gray-200
  }

  &-sm {
    @apply px-2.5 py-1.5 text-xs rounded;
  }

  &-md {
    @apply px-4 py-2 leading-5;
  }

  &-lg {
    @apply px-4 py-2 text-base leading-6;
  }

  &-xl {
    @apply px-6 py-3 text-base leading-6;
  }

  &-primary {
    @apply text-white bg-green-500;
    
    &:hover {
      @apply bg-green-500;
    }

    &:focus {
      @apply border-green-600 outline-none shadow-outline-green;
    }

    &:active {
      @apply bg-green-500
    }
  }

  &-secondary {
    @apply text-gray-700 bg-white border border-gray-300;
    
    &:hover {
      @apply text-gray-900 bg-white border-gray-400;
    }

    &:focus {
      @apply border-gray-500 outline-none shadow-outline-blue;
    }

    &:active {
      @apply text-gray-800 bg-gray-100;
    }
  }

  &-transparent {
    @apply text-gray-700 bg-transparent;
    &:hover {
      @apply text-gray-900 bg-gray-200;
    }
    &:active {
      @apply bg-gray-300;
    }
  }

  &-link {
    @apply px-1 bg-transparent text-primary-600;
    &:hover {
      @apply text-gray-800;
    }
  }
  
  &[disabled], &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: .5;
  }
}