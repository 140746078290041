up-modal.custom-modal {
  up-modal-box {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
}
up-modal.pmls-modal {
  up-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }
  up-modal-box {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: none;
    @apply shadow-xl;
  }
  up-modal-content {
    padding: 10px;
  }
}