@import "tailwindcss/base";
@import "tailwindcss/components";
@import "./legacy.scss";
@import "../../shared/styles/components/*.css";
@import "./components/*.css";
@import "tailwindcss/utilities";
@import "./bs-select.css";
[x-cloak] { display: none !important; }
 
#admin-nav-links::-webkit-scrollbar-track{
  background: #0d586e;
}
