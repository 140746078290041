.form-bs-select {
  @apply form-select;


  /* from bootstrap */
  display: block;
  width: 100%;

  /* border */
  @apply border-gray-200 border rounded-md;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  /* background */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.form-bs-select:focus {
  border-color: #00c69b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 198 155 / 25%);
}

.form-bs-select.form-bs-select-compact {
  padding-right: 2.0rem;
}

.custom-select {
  appearance: none;

  /* background */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}